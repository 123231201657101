import $ from 'jquery'

document.addEventListener('scroll', function (event) {
  const scroll = document.documentElement.scrollTop || document.body.scrollTop
  if (scroll <= 300) $("#backToTop__button").addClass("hidden")
  else $("#backToTop__button").removeClass("hidden")
}, true)

function scrollTop() {
  $("html, body").animate({ scrollTop: "0px" })
}

window.scrollTop = scrollTop